import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import '../styles/styles.scss'

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Helmet>
              <html lang="sv" />
              <meta charset="utf-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1" />
              <meta http-equiv="X-UA-Compatible" content="IE=egde" />
              <title>{data.site.siteMetadata.title}</title>
              <meta name="description" content={data.site.siteMetadata.description} />
              <meta name="robots" content="index,follow" />
              <link rel="canonical" href="https://healfy.se/" />
              <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
              <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
              <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
              <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
              <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
              <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
              <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
              <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
              <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="manifest" href="/manifest.json" />
              <meta name="msapplication-TileColor" content="#ffffff" />
              <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
              <meta name="theme-color" content="#ffffff" />
              <link href="https://fonts.googleapis.com/css?family=Expletus+Sans:700|Open+Sans:400,700,800" rel="stylesheet" />
            </Helmet>
            {children}
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
